import React from 'react';
// import gitHub from '../images/github_fpykxh.png';
// import linkedIn from '../images/linkedin_vnvo6s.png';
export default function Footer(props) {

  return (
    <footer>
      <div id="footer-group">
        <div id="footer-links">
          {/* <i className="im im-instagram"></i> */}
          {/* <i className="im im-youtube"></i> */}
          <i className="im im-facebook"></i>
          {/* <i className="im im-twitter"></i> */}
          {/* <i className="im im-linkedin"></i> */}
        </div>
      </div>
      <p id='copyright'>The Dog People LLC 2022</p>
    </footer>
  )
}

