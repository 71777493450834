import { sendEmailAPI } from '../services/api-helper';
import React, { useState } from 'react';

export default function ContactUsForm() {

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [helpMessage, setHelpMessage] = useState('');
  const sendEmail = async () => {
    try {
      await sendEmailAPI(name, phoneNumber, emailAddress, helpMessage);
      // console.log(name, phoneNumber, emailAddress, helpMessage);
      // console.log(response);
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="add-item-comp">
      <div className="mobile-header">Add Listing</div>
      <form className="add-item-form" >
        <label>Name</label>
        <input type='text' value={name} onChange={e => setName(e.target.value)} />
        <label htmlFor="Email Address">Email Address *</label>
        <input
          type="text"
          // name="emailAddress"
          // id="emailAddress"
          value={emailAddress}
          onChange={e => setEmailAddress(e.target.value)}
        />
        <label htmlFor="phoneNumber">Phone Number</label>
        <input
          type="text"
          // name="phoneNumber"
          // id="phoneNumber"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
        <label>How may we help you? *</label>
        <textarea value={helpMessage} onChange={e => setHelpMessage(e.target.value)} />

      </form>
      <button id="add-new-item-btn" onClick={() => { sendEmail(); }} >Send</button>
    </div>
  )
}