import React, { useState, useEffect } from 'react'
import { getCustomersAPI, getCustomerAPI } from '../services/api-helper';
import { JSONTree } from 'react-json-tree';
import CustomerTable from './CustomerTable';
import { useNavigate } from "react-router-dom";

function Customers(props) {

  const [customers, setCustomers] = useState([])
  const [customerJson, setCustomerJson] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [customerNames, setCustomerNames] = useState('')
  let navigate = useNavigate();

  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633'
  };

  useEffect(() => {
    try {
      if (props?.currentUser) {
        getCustomers();
      } else {
        navigate('/login');
      }
    } catch (err) {
      console.log('err=', err);
      navigate('/login');
    }
  }, [props]);
  
  const getCustomers = async (event) => {
    setCustomers(null);
    const myCustomers = await getCustomersAPI();
    let custarr = [...myCustomers];
    custarr.unshift({ idcustomer: 0, firstname: '' })
    setCustomerNames(custarr.map(customer => ((<option key={customer.idcustomer} value={customer.idcustomer}>{customer.firstname}</option>))));
    setCustomers(myCustomers);
  }
  const getCustomer = async (event) => {
    setCustomerName(event.target.value);
    const customer = await getCustomerAPI(event.target.value);
    setCustomerJson(customer);
  }
  return (
    <div className="masonry">
      <label>Customers</label>
      <select value={customerName} onChange={getCustomer} name="customerNames" type="customerNames" placeholder="customerNames">
        {customerNames}
      </select>
      <JSONTree data={customerJson} theme={theme} hideRoot={true} />
      <CustomerTable customers={customers} refreshCustomers={getCustomers} />
    </div>
  )
}

export default Customers;