import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddDog from './AddDog';
import AddSale from './AddSale';
import AddPayment from './AddPayment';
import { getPaymentTypesAPI } from '../services/api-helper';

function AddToCustomerModal(props) {
  const [item, setItem] = useState([]);
  const [opts, setOpts] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState(null);

  const setSelection = async (event) => {
    console.log(event.target.value);
    setItem(event.target.value);
  }

  const closeModal = async () => {
    setItem(null);
    props.onHide();
  }
  const getPaymentTypes = async () => {
    const pts = await getPaymentTypesAPI();
    pts.unshift({ idpaymenttype: 0, name: '' })
    setPaymentTypes(pts.map(pt => ((<option key={pt.idpaymenttype} value={pt.idpaymenttype}>{pt.name}</option>))));
  }
  useEffect(() => {
    let options = [<option key="0" value=""></option>];
    options.push(<option key="1" value="Dog">Dog</option>);
    if (props.currentCustomer) {
      if (!paymentTypes) getPaymentTypes();
      if (props.currentCustomer.dogs && props.currentCustomer.dogs.length > 0) options.push(<option key="2" value="Sale">Sale</option>);
      if (props.currentCustomer.sales && props.currentCustomer.sales.length > 0) options.push(<option key="3" value="Payment">Payment</option>);
      setOpts(options);
    }
  }, [props.currentCustomer]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Customer Actions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.currentCustomer &&
          <>
            <label>Select what you want to add to Customer: {props.currentCustomer.firstname} {props.currentCustomer.lastname}</label>
            <select name="items" onChange={setSelection}>
              {opts}
            </select>
          </>
        }
        {item && item === "Dog" &&
          <>
            <p id="add-title">Add {item}</p>
            <AddDog customer={props.currentCustomer} closeModal={closeModal} />
          </>
        }
        {item && item === "Sale" &&
          <>
            <p id="add-title">Add {item}</p>
            <AddSale customer={props.currentCustomer} closeModal={closeModal} />
          </>
        }
        {item && item === "Payment" &&
          <>
            <p id="add-title">Add {item}</p>
          <AddPayment
            customer={props.currentCustomer}
            paymentTypes={paymentTypes}
            closeModal={closeModal} />
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
export default AddToCustomerModal;