import React, { useState, useEffect } from 'react'
import { verifyUser, getExpensesAPI, getSalesReportAPI, getOutstandingReportAPI, getSalesWithPaymentsReportAPI, getSalesByPaymentTypeReportAPI, getSalesByMonthReportAPI } from '../services/api-helper';
import Products from './Products';
import Customers from './Customers';
import Expenses from './Expenses';
import SimpleTable from './SimpleTable';
import { useNavigate } from "react-router-dom";
import SalesByMonth from './SalesByMonth';

function Admin(props) {
  const [currentUser, setcurrentUser] = useState(null);
  let navigate = useNavigate();
  const [item, setItem] = useState([]);
  const [expenses, setExpenses] = useState([]);
  //for SimpleTable
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [exportFields, setExportFields] = useState('');


  const getSalesByMonthReport = async () => {
    const sbm = await getSalesByMonthReportAPI();
    setData(sbm);
  }
  const getExpenses = async () => {
    const exp = await getExpensesAPI();
    setExpenses(exp);
  }
  const getSalesReport = async () => {
    const sr = await getSalesReportAPI();
    setData(sr);
    setColumns([
      { title: 'idsales', field: 'idsales', type: "numeric" },
      { title: 'First Name', field: 'firstname' },
      { title: 'Last Name', field: 'lastname' },
      { title: 'Sale Total', field: 'total', type: "currency" },
      { title: 'Comments', field: 'comments' },
      { title: 'Dog Name', field: 'dogName' },
      { title: 'Start Date', field: 'startdate', type: "date" },
      { title: 'End Date', field: 'enddate', type: "date" },
    ]);
    setExportFields({ "idsales": "Sale Id", "firstname": "First Name", "lastname": "Last Name", "total": "Total", "comments": "Comments", "dogName": "Dog Name", "startdate": "Start Date", "enddate": "End Date" });
  }
  const getSalesWithPaymentsReport = async () => {
    const sr = await getSalesWithPaymentsReportAPI();
    setData(sr);
    setColumns([
      { title: 'idsales', field: 'idsales', type: "numeric" },
      { title: 'First Name', field: 'firstname' },
      { title: 'Last Name', field: 'lastname' },
      { title: 'Sale Total', field: 'total', type: "currency" },
      { title: 'Comments', field: 'comments' },
      { title: 'Dog Name', field: 'dogName' },
      { title: 'Start Date', field: 'startdate', type: "date" },
      { title: 'End Date', field: 'enddate', type: "date" },
      { title: 'Total Paid', field: 'totalPaid', type: "currency" },
      { title: 'Payment Date', field: 'paymentDate', type: "date" },
      { title: 'Payment Type', field: 'paymentType' },
    ]);
    setExportFields({ "idsales": "Sale Id", "firstname": "First Name", "lastname": "Last Name", "total": "Total", "comments": "Comments", "dogName": "Dog Name", "startdate": "Start Date", "enddate": "End Date", "totalPaid": "Total Paid", "paymentDate": "Payment Date", "paymentType": "Payment Type" });
  }
  const getSalesByPaymentTypeReport = async () => {
    const sr = await getSalesByPaymentTypeReportAPI();
    setData(sr);
    setColumns([
      { title: 'Name', field: 'type' },
      { title: 'Sales Total', field: 'total', type: "currency" }
    ]);
    setExportFields({ "name": "Name", "salesTotal": "Sales Total" });
  }
  const getOutstandingReport = async () => {
    const out = await getOutstandingReportAPI();
    setData(out);
    setColumns([
      { title: 'idsales', field: 'idsales', type: "numeric" },
      { title: 'Sale Notes', field: 'saleNotes' },
      { title: 'Dog Name', field: 'dogName' },
      { title: 'Start Date', field: 'startdate', type: "date" },
      { title: 'First Name', field: 'firstname' },
      { title: 'Last Name', field: 'lastname' },
      { title: 'Sale Total', field: 'salesTotal', type: "currency" },
      { title: 'Total Paid', field: 'paymentsTotal', type: "currency" },
      { title: 'Balance', field: 'balance', type: "currency" }
    ]);
    setExportFields({ "idsales": "Sale Id", "saleNotes": "Sale Notes", "dogName": "Dog Name", "startdate": "Start Date", "firstname": "First Name", "lastname": "Last Name", "salesTotal": "Sales Total", "paymentsTotal": "Total Paid", "balance": "Balance" });
  }
  const setSelection = async (event) => {
    // console.log("in setSelection", event.target.value)
    setItem(event.target.value);
    switch (event.target.value) {
      case "expenses":
        console.log("expenses");
        getExpenses();
        break;
      case "salesreport":
        console.log("salesreport");
        getSalesReport();
        break;
      case "saleswPaymentsreport":
        console.log("saleswPaymentsreport");
        getSalesWithPaymentsReport();
        break;
      case "outstandingreport":
        console.log("outstandingreport");
        getOutstandingReport();
        break;
      case "salesbyptreport":
        console.log("salesbyptreport");
        getSalesByPaymentTypeReport();
        break;
      case "salesbymonth":
        console.log("salesbymonth");
        getSalesByMonthReport();
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    // console.log("useEffect ran");

    const verify = async () => {
      try {
        const user = await verifyUser();
        // console.log("currentuser=", currentUser);
        if (user) {
          // console.log("user logged in");
          setcurrentUser(user);
          return true;
        } else {
          // console.log('pushing to login');
          navigate('/login');
        }
      } catch (err) {
        console.log('err=', err);
        // console.log('pushing to login');
        navigate('/login');
      }
    }
    verify();

  }, [item]);

  return (
    <>
      <div>
        <label>Select a Function</label>
        <select name="items" onChange={setSelection}>
          <option key='0' value='none'></option>
          <option key='1' value='customers'>Customers</option>
          <option key='2' value='products'>Products</option>
          <option key='3' value='expenses'>Expenses</option>
          <option key='4' value='salesreport'>Sales Report</option>
          <option key='5' value='saleswPaymentsreport'>Sales With Payments Report</option>
          <option key='6' value='salesbyptreport'>Sales By Payment Type Report</option>
          <option key='7' value='outstandingreport'>Money Due Report</option>
          <option key='8' value='salesbymonth'>Sales by Month Graph</option>
        </select>
      </div>
      {item && item === "customers" &&
        <Customers currentUser={currentUser} />
      }
      {item && item === "products" &&
        <Products currentUser={currentUser} />
      }
      {item && item === "expenses" &&
        <Expenses
          currentUser={currentUser}
          expenses={expenses} />
      }
      {item && item === "salesreport" &&
        <SimpleTable
          currentUser={currentUser}
          data={data}
          columns={columns}
          exportFileName="sales"
          exportFields={exportFields}
          title="Sales Report"
          pageSize="10"
        />
      }
      {item && item === "saleswPaymentsreport" &&
        <SimpleTable
          currentUser={currentUser}
          data={data}
          columns={columns}
          exportFileName="sales"
          exportFields={exportFields}
          title="Sales Report"
          pageSize="10"
        />
      }
      {item && item === "outstandingreport" &&
        <SimpleTable
          currentUser={currentUser}
          data={data}
          columns={columns}
          exportFileName="outstanding"
          exportFields={exportFields}
          title="Money Due"
          pageSize="5"
        />
      }
      {item && item === "salesbyptreport" &&
        <SimpleTable
          currentUser={currentUser}
          data={data}
          columns={columns}
          exportFileName="summary"
          exportFields={exportFields}
          title="Sales By Payment Type"
          pageSize="10"
        />
      }
      {item && item === "salesbymonth" &&
        <SalesByMonth
          currentUser={currentUser}
          data={data}
        />
      }
    </>
  )
}
export default Admin;