import React from 'react';
import { Link } from 'react-router-dom';
import tdp from '../images/dp.png'
export default function Nav() {

  return (
    <div id='nav-box'>
      <img id='tdpImg' src={tdp} alt=''></img>
      <nav id='nav'>
        <Link id="nav-link" to='/'>Home</Link>
        <Link id="nav-link" to='/services'>Services</Link>
        <Link id="nav-link" to='/contactus'>Contact Us</Link>
        <Link id="nav-link" to='/aboutus'>About Us</Link>
      </nav>
      <hr id='line'></hr>
    </div>
  )
}

