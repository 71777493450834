import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, AreaChart, Area, ResponsiveContainer } from 'recharts';
import { useNavigate } from "react-router-dom";

function SalesByMonth(props) {
  useEffect(() => {
    try {
      if (props?.currentUser) {
      } else {
        navigate('/login');
      }
    } catch (err) {
      console.log('err=', err);
      navigate('/login');
    }

  }, [props]);
  let navigate = useNavigate();
  // const data = [
  //   {
  //     "number": 1,
  //     "name": "January",
  //     "count": 4,
  //     "total": 1282
  //   },
  //   {
  //     "number": 2,
  //     "name": "February",
  //     "count": 4,
  //     "total": 6185
  //   },
  //   {
  //     "number": 3,
  //     "name": "March",
  //     "count": 7,
  //     "total": 9810
  //   },
  //   {
  //     "number": 4,
  //     "name": "April",
  //     "count": 9,
  //     "total": 13719
  //   },
  //   {
  //     "number": 5,
  //     "name": "May",
  //     "count": 5,
  //     "total": 7520
  //   },
  //   {
  //     "number": 6,
  //     "name": "June",
  //     "count": 3,
  //     "total": 5355
  //   },
  //   {
  //     "number": 7,
  //     "name": "July",
  //     "count": 10,
  //     "total": 5820
  //   },
  //   {
  //     "number": 8,
  //     "name": "August",
  //     "count": 2,
  //     "total": 3082
  //   }
  // ];

  return (
    <>
      <h1 id="ctr">Sales By Month</h1>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          {/* <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="count" stroke="#82ca9d" /> */}
          <Line yAxisId="left" type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line yAxisId="right" type="monotone" dataKey="count" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default SalesByMonth;