import React, { useState, useEffect } from 'react';
import { addDogAPI } from '../services/api-helper';
// import { InputGroup, FormControl, Button } from 'react-bootstrap';

const AddDog = (props) => {

  const [name, setName] = useState(null);
  const [age, setAge] = useState(null);
  const [comments, setComments] = useState(null);
  const [weight, setWeight] = useState(null);
  const [breed, setBreed] = useState(null);
  const [idCustomer, setIdCustomer] = useState(null);
  // const [selection, setSelection] = useState(null);
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);


  useEffect(() => {
    setIdCustomer(props.customer.idcustomer);
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls);
    // console.log("props=",props)
  }, [images]);

  const addNewDog = async () => {
    // const image = images.shift();
    await addDogAPI({ name, age, comments, weight, breed, "idcustomer": idCustomer }, images);
    props.closeModal();
  }
  function addNewImage(event) {
    console.log("im here");
    console.log("event.target.files=", event.target.files);
    // let i = images;
    // i.push(image);
    // setImages(i);
    // setSelectedImage("");

    setImages([...event.target.files]);
  }

  return (
    <div className="add-item-comp">
      <div className="mobile-header">Add Listing</div>
      <form className="add-item-form" >
        <label>Name</label>
        <input type='text' value={name} onChange={e => setName(e.target.value)} />
        <label>Age</label>
        <input type='number' value={age} onChange={e => setAge(e.target.value)} />
        <label>Breed</label>
        <input type='text' value={breed} onChange={e => setBreed(e.target.value)} />
        <label>Weight</label>
        <input type='number' value={weight} onChange={e => setWeight(e.target.value)} />
        <label>Comments</label>
        <textarea value={comments} onChange={e => setComments(e.target.value)} />
      </form>

      <input type='file' multiple accept="image/*" onChange={addNewImage} />
      <div className="image-list">
        {/* <InputGroup className="mb-3">
          <FormControl
            placeholder="Add Image"
            aria-label="Add Image"
            aria-describedby="basic-addon2"
            value={selectedImage}
            onChange={e => setSelectedImage(e.target.value)}
          />
          <Button variant="outline-secondary" onClick={() => { addNewImage(selectedImage); }}>Add</Button>
        </InputGroup> */}
        <div>
          {imageURLs.map(imageSrc => <img className="new-item-images" src={imageSrc} alt="none" />)}
        </div>
      </div>
      <button id="add-new-item-btn" onClick={() => { addNewDog(); }} >Add</button>
      {/* <p>{selection}</p> */}
    </div>
  )
}

export default AddDog;