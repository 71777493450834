import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import { addExpenseAPI, deleteExpenseAPI } from '../services/api-helper';
import { useNavigate } from "react-router-dom";
// import { useParams, useNavigate, useLocation } from "react-router-dom";
import exportFromJSON from 'export-from-json'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import SearchableTextBox from './SearchableTextBox';

import {
  AddBox,
  // ArrowUpward,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function Expenses(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns([
      { title: 'idexpense', field: 'idexpense', type: "numeric", hidden: true },
      {
        title: 'Payee', field: 'payee',
        editComponent: ({ value, onRowDataChange, rowData }) => (
          <SearchableTextBox
            list="payees"
            placeholder="Enter a payee name"
            data={[...new Set(props.expenses.map(item => item.payee))]}
            onChange={(value) => {
              onRowDataChange({
                ...rowData,
                payee: (value) ?? "",
              });
            }}
          />
        ), validate: rowData => rowData.payee === '' || rowData.payee === undefined ? { isValid: false, helperText: 'Payee cannot be empty' } : true,
      },
      // { title: 'Payee', field: 'payee', validate: rowData => rowData.payee === '' || rowData.payee === undefined ? { isValid: false, helperText: 'Payee cannot be empty' } : true, },
      { title: 'Amount', field: 'amount', type: "currency", validate: rowData => rowData.amount === undefined ? { isValid: false, helperText: 'Amount cannot be empty' } : true, },
      { title: 'Description', field: 'description', validate: rowData => rowData.description === '' || rowData.description === undefined ? { isValid: false, helperText: 'Description cannot be empty' } : true, },
      { title: 'Misc', field: 'misc' },
      {
        title: 'Purchase Date',
        field: 'purchase_date',
        initialEditValue: new Date(),
        type: "date",
        editComponent: ({ value, onRowDataChange, rowData }) => (
          <DatePicker selected={value} onChange={(event) => {
            // console.log(event);
            onRowDataChange({
              ...rowData,
              purchase_date: event
            });

          }} />
        )
      },
      { title: 'created_at', field: 'created_at', hidden: true },
      { title: 'updated_at', field: 'updated_at', hidden: true }]);
    try {
      if (props?.currentUser) {
      } else {
        navigate('/login');
      }
    } catch (err) {
      console.log('err=', err);
      navigate('/login');
    }
    setData(props.expenses);
  }, [props]);

  const tableRef = useRef();

  let navigate = useNavigate();
  const fileName = 'expenses';
  const exportType = 'csv';

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();
  const handleRowAdd = (newData, resolve) => {
    addExpenseAPI(newData)
      .then(res => {
        // console.log("handleRowAdd newData=", newData);
        newData.idexpense = res;
        // console.log("id=", res);
        // console.log("rowadd2 newData=", newData);
        setData([...data, newData]);
        resolve();
      })
      .catch(error => {
        resolve()
      })
  }
  const exportExcel = (data) => {
    console.log("bendata", data);
    exportFromJSON({ data, fileName, exportType, fields: { "payee": "Payee", "amount": "Amount", "description": "Description", "misc": "Misc", "purchase_date": "Purchase Date" } });
  }
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          options={{
            search: true,
            pageSizeOptions: [5, 10, 20, 40],
            pageSize: 5,
            exportButton: true,
            exportCsv: (columns, data) => {
              exportExcel(data);
            },
            exportPdf: (columns, data) => {
              alert('Unavailable');
            }
          }}
          title="Expenses"
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          data={data}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                newData.purchase_date = moment(new Date(newData.purchase_date)).format("YYYY-MM-DD")
                // console.log(newData);
                handleRowAdd(newData, resolve)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // console.log("oldData=", oldData);
                  deleteExpenseAPI(oldData.idexpense);
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),
          }
          }
        />
      </ThemeProvider>
    </div>
  )
}
export default Expenses;