import React from 'react';
import { Link } from 'react-router-dom';
// import tdp from '../images/dp.png'
export default function Header(props) {

  return (
    <header id='header'>
      {/* <img id='tdpImg' src={tdp}></img> */}
      <Link to="/customers"><button id='book-button'>Book</button></Link>
      {/* <line id="line"></line> */}
    </header>
  )
}

