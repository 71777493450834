import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import AddToCustomerModal from './AddToCustomerModal';
import DatePicker from "react-datepicker";
import { addCustomerAPI, updateCustomerAPI, deleteCustomerAPI, deleteDogAPI, updateDogAPI, deletePaymentAPI, deleteSaleAPI } from '../services/api-helper';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function CustomerTable(props) {
  const [columns, setColumns] = useState([]);
  const [dogColumns, setDogColumns] = useState([]);
  const [paymentsColumns, setPaymentsColumns] = useState([]);
  const [salesColumns,setSalesColumns] = useState([]);
  const [productsColumns,setProductsColumns] = useState([]);
  
  const createColumns = () => {
    setColumns([
      { title: 'First Name', field: 'firstname', validate: rowData => rowData.firstname === '' || rowData.firstname === undefined ? { isValid: false, helperText: 'First Name cannot be empty' } : true, },
      { title: 'Last Name', field: 'lastname', validate: rowData => rowData.lastname === '' || rowData.lastname === undefined ? { isValid: false, helperText: 'Last Name cannot be empty' } : true, },
      { title: 'Email', field: 'email' },
      { title: 'Phone Number', field: 'phonenumber', validate: rowData => rowData.phonenumber === '' || rowData.phonenumber === undefined ? { isValid: false, helperText: 'Phone Number cannot be empty' } : true, },
      { title: 'Address 1', field: 'address1' },
      { title: 'Address 2', field: 'address2' },
      { title: 'City', field: 'city' },
      { title: 'state', field: 'state' },
      { title: 'Zip', field: 'zip', type: 'numeric' },
      { title: 'idcustomer', field: 'idcustomer', type: 'numeric', hidden: true },
      { title: 'created_at', field: 'created_at', hidden: true },
      { title: 'updated_at', field: 'updated_at', hidden: true },
    ]);

    setDogColumns([
      { title: '', field: 'img', render: item => <img src={item.imageurl} alt="" border="3" height="100" width="100" /> },
      { title: 'Name', field: 'name', validate: rowData => rowData.name === '' || rowData.name === undefined ? { isValid: false, helperText: 'Name cannot be empty' } : true, },
      { title: 'Breed', field: 'breed', validate: rowData => rowData.breed === '' || rowData.breed === undefined ? { isValid: false, helperText: 'Breed cannot be empty' } : true, },
      { title: 'Age', field: 'age', type: 'numeric' },
      { title: 'Weight', field: 'weight', type: 'numeric', validate: rowData => rowData.weight === undefined ? { isValid: false, helperText: 'Weight must be greater than 0' } : true, },
      { title: 'Comments', field: 'comments' },
      { title: 'iddog', field: 'iddog', type: 'numeric', hidden: true },
      { title: 'created_at', field: 'created_at', hidden: true },
      { title: 'updated_at', field: 'updated_at', hidden: true },
    ]);
    setPaymentsColumns([
      { title: 'Transaction Number', field: 'transaction_number' },
      { title: 'Name', field: 'name', validate: rowData => rowData.name === '' || rowData.name === undefined ? { isValid: false, helperText: 'Name cannot be empty' } : true, },
      { title: 'Description', field: 'description' },
      { title: 'Payment Description', field: 'ptdesc' },
      { title: 'Total', field: 'total', type: 'currency', validate: rowData => rowData.total === undefined ? { isValid: false, helperText: 'Total must be greater than 0' } : true, },
      {
        title: 'Payment Date', field: 'updated_at',
        initialEditValue: new Date(),
        type: "date",
        editComponent: ({ value, onRowDataChange, rowData }) => (
          <DatePicker selected={value} onChange={(event) => {
            console.log(event);
            onRowDataChange({
              ...rowData,
              updated_at: event
            });

          }} />
        )
      },
    ]);
    setSalesColumns([
      {
        title: 'Sale Date',
        field: 'created_at',
        initialEditValue: new Date(),
        type: "date",
        editComponent: ({ value, onRowDataChange, rowData }) => (
          <DatePicker selected={value} onChange={(event) => {
            console.log(event);
            onRowDataChange({
              ...rowData,
              created_at: event
            });

          }} />
        )
      },
      { title: 'Comments', field: 'comments', validate: rowData => rowData.comments === '' || rowData.comments === undefined ? { isValid: false, helperText: 'Comments cannot be empty' } : true, },
      { title: 'Total', field: 'total', type: "currency", validate: rowData => rowData.total === undefined ? { isValid: false, helperText: 'Total must be greater than 0' } : true, },
      { title: 'Dog Name', field: 'dogName' },
      { title: 'Dog Age', field: 'dogAge', type: "numeric" },
      { title: 'Dog Weight', field: 'dogWeight', type: "numeric" },
      { title: 'idsales', field: 'idsales', type: 'numeric', hidden: true },
      { title: 'updated_at', field: 'updated_at', hidden: true },
    ]);
    setProductsColumns([
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      { title: 'Price', field: 'productPrice' },
      { title: 'Adjusted Price', field: 'adjustedPrice' },
      { title: 'Cost', field: 'cost' },
      { title: 'Comments', field: 'comments' },
      { title: 'idproduct', field: 'idproduct', type: 'numeric', hidden: true },
    ]);
  }
  const [data, setData] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const tableRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [isOpen, setIsOpen] = useState({ menuOpen: false });


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();
  useEffect(() => {
    createColumns();
    setData(props.customers);
    setIsOpen({ menuOpen: false });
    return () => {
      setColumns(null);
    }
  }, [props]);
  
  const handleAddCustomer = (newData, resolve) => {
    addCustomerAPI(newData)
      .then(res => {
        newData.idcustomer = res;
        setData([...data, newData]);
        resolve();
      })
      .catch(error => {
        resolve()
      })
  }

  return (
    <div className="masonry">
      <AddToCustomerModal
        currentCustomer={currentCustomer}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          props.refreshCustomers();
        }
        }
      />
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title="Customers"
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          data={data}
          actions={[
            (rowData => ({
              icon: AddBox,
              tooltip: "Add row",
              onClick: (event, newRow) => {
                setCurrentCustomer(newRow);
                setModalShow(true);
              }
            }))({ position: 'toolbar' })
          ]}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                handleAddCustomer(newData, resolve)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  updateCustomerAPI(newData);
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                }, 1000)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteCustomerAPI(oldData);
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),

          }
          }
          detailPanel={rowData => {
            return (<>
              <MaterialTable
                icons={tableIcons}
                title="Dogs"
                columns={dogColumns}
                data={rowData.dogs || []}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        updateDogAPI(newData);
                        props.refreshCustomers();
                        resolve();
                      }, 1000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        deleteDogAPI(oldData.iddog);
                        props.refreshCustomers();
                        resolve()
                      }, 1000)
                    }),
                }
                }
                style={{
                  backgroundColor: "aliceblue"
                }}
              />
              <MaterialTable
                icons={tableIcons}
                title="Sales"
                columns={salesColumns}
                data={rowData.sales || []}
                editable={{
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        deleteSaleAPI(oldData.idsales)
                        resolve()
                      }, 1000)
                    }),
                }}

                // start products
                detailPanel={rowData => {
                  return (<>
                    <MaterialTable
                      icons={tableIcons}
                      title="Products"
                      columns={productsColumns}
                      data={rowData.products || []}
                      style={{
                        backgroundColor: "aliceblue"
                      }}
                    />
                    <MaterialTable
                      icons={tableIcons}
                      title="Payments"
                      columns={paymentsColumns}
                      data={rowData.payments || []}
                      editable={{
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              deletePaymentAPI(oldData.idpayment);
                              props.refreshCustomers();
                              resolve()
                            }, 1000)
                          }),
                      }
                      }
                      style={{
                        backgroundColor: "aliceblue"
                      }}
                    />
                  </>
                  );
                }
                }
                // end payments
                style={{
                  backgroundColor: "aliceblue"
                }}
              />
            </>
            );
          }}
        />
      </ThemeProvider>
    </div>
  )
}
export default CustomerTable;