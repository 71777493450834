import React, { useState, useEffect } from 'react';
import { addPaymentAPI } from '../services/api-helper';
// import { InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const AddPayment = (props) => {

  const [total, setTotal] = useState(0);
  const [transactionNumber, setTransactionNumber] = useState('');
  const [description, setDescription] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [sales, setSales] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedIdSales, setSelectedIdSales] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!sales && props.customer.sales) {
      let arr = props.customer.sales;
      console.log(arr);
      let s = arr.map(sale => ((<option key= {sale.idsales} value={sale.idsales}>{sale.dogName} - {sale.comments} - {moment(new Date(sale.created_at)).format("MM/DD/YYYY")}</option>)));
      s.unshift(<option key='0' value='0'></option>)
      setSales(s);
    }
    validateForm(null);
    if (!paymentTypes) setPaymentTypes(props.paymentTypes);
  }, [paymentDate, total, selectedPaymentType, selectedIdSales]);

  const addPayment = async () => {
    await addPaymentAPI({
      "idpaymenttype": selectedPaymentType,
      "idsales": selectedIdSales,
      description,
      "transaction_number": transactionNumber,
      "created_at": paymentDate,
      total
    });
    props.closeModal();
  }
  const selectSale = async (id) => {
    console.log("id=",id);
    console.log("props.customer.sales=",props.customer.sales);
    setSelectedIdSales(id);
    const found = props.customer.sales.find(sale => sale.idsales == id);
    console.log("found=",found);
    let salesTotal = found.total;
    console.log("salesTotal1=",salesTotal);
    if (found.payments) {
      const paymentTotal = found.payments.reduce((accumulator, object) => {
        return accumulator + object.total;
      }, 0);
      salesTotal = salesTotal - paymentTotal;
    } else {
    }
    console.log("salesTotal2=",salesTotal);
    if (salesTotal > 0) validateForm(null); //setDisabled(false);
    setTotal(salesTotal);
  }
  const validateForm = async (e) => {
    if (selectedIdSales && selectedPaymentType && total && paymentDate) {
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }
  }
  return (
    <div className="add-item-comp">
      <div className="mobile-header">Add Payment</div>
      <form>
        <div id="sale-flex">
          <label>Sale</label><div></div>
          <select value={selectedIdSales} onChange={e => selectSale(e.target.value)} name="sales" type="sales" placeholder="sales">
            {sales}
          </select>
          <div id="sbs">
          <label>Payment Type</label><label>Payment Date</label>
          <select value={selectedPaymentType} onChange={e => setSelectedPaymentType(e.target.value)} name="pts" type="pts" placeholder="pts">
            {paymentTypes}
          </select>
            <DatePicker selected={paymentDate} onChange={(date) => setPaymentDate(date)} />
            </div>
          <label>Total</label><div></div>
          <InputGroup className="mb-3">
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control value={total} onChange={e => setTotal(e.target.value)} />
            <InputGroup.Text>.00</InputGroup.Text>
          </InputGroup><div></div>
          <label>Transaction Number</label>
          <input type='text' value={transactionNumber} onChange={e => setTransactionNumber(e.target.value)} />
          <label>Description</label><div></div>
        </div>
        <textarea id="add-sales-comments" value={description} onChange={e => setDescription(e.target.value)} />
      </form>
      <button id="add-new-item-btn" disabled={disabled} onClick={() => { addPayment(); }} >Add</button>
    </div>
  )
}

export default AddPayment;