import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import { verifyUser, getProductsAPI, addProductAPI, updateProductAPI, deleteProductAPI } from '../services/api-helper';
import {  useNavigate } from "react-router-dom";
// import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  AddBox,
  // ArrowUpward,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function Products(props) {
  let navigate = useNavigate();

  const getProducts = async () => {
    // console.log("useEffect ran");
    const verify = async () => {
      try {
        const user = await verifyUser();
        // console.log("currentuser=", currentUser);
        if (!user) {
          // console.log('pushing to login');
          navigate('/login');
        }
      } catch (err) {
        console.log('err=', err);
        // console.log('pushing to login');
        navigate('/login');
      }
    }
    // console.log(currentUser);
    if (verify()) {
      // console.log("after verify");
      const prods = await getProductsAPI();
      setData(prods);
    }
  }
  const [columns] = useState([
    { title: 'idproduct', field: 'idproduct', type: "numeric", hidden: true },
    { title: 'Name', field: 'name', validate: rowData => rowData.name === '' || rowData.name === undefined ? { isValid: false, helperText: 'Name cannot be empty' } : true, },
    { title: 'Price', field: 'price', type: "currency", validate: rowData => rowData.price === '' || rowData.price === undefined ? { isValid: false, helperText: 'Price must be greater than 0' } : true, },
    { title: 'Cost', field: 'cost', type: "currency", validate: rowData => rowData.cost === '' || rowData.cost === undefined ? { isValid: false, helperText: 'Cost must be greater than 0' } : true, },
    { title: 'Description', field: 'description', validate: rowData => rowData.description === '' || rowData.description === undefined ? { isValid: false, helperText: 'Description cannot be empty' } : true, },
    { title: 'created_at', field: 'created_at', hidden: true },
    { title: 'updated_at', field: 'updated_at', hidden: true }]);

  useEffect(() => {
    getProducts();
  }, [props]);
  const [data, setData] = useState([]);

  const tableRef = useRef();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();

  const handleRowAdd = (newData, resolve) => {
    addProductAPI(newData)
      .then(res => {
        console.log("handleRowAdd newData=", newData);
        newData.idproduct = res;
        console.log("id=", res);
        console.log("rowadd2 newData=", newData);
        setData([...data, newData]);
        resolve();
      })
      .catch(error => {
        resolve()
      })
  }
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          options={{
            search: true
          }}
          title="Products"
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          data={data}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                handleRowAdd(newData, resolve)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log("rowupdate newData=", newData);
                  updateProductAPI(newData.idproduct,newData);
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                }, 1000)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log("oldData=", oldData);
                  deleteProductAPI(oldData.idproduct);
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),

          }
          }
        />
      </ThemeProvider>
    </div>
  )
}

export default Products;