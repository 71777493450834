import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import { useNavigate } from "react-router-dom";
import exportFromJSON from 'export-from-json'
import "react-datepicker/dist/react-datepicker.css";

import {
  AddBox,
  // ArrowUpward,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function SimpleTable(props) {

  useEffect(() => {
    try {
      if (props?.currentUser) {
      } else {
        navigate('/login');
      }
    } catch (err) {
      console.log('err=', err);
      navigate('/login');
    }
  }, [props]);

  const tableRef = useRef();
  let navigate = useNavigate();
  const fileName = props.exportFileName;
  const exportType = 'csv';

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();

  const exportExcel = (data) => {
    exportFromJSON({ data, fileName, exportType, fields: props.exportFields });
  }
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          options={{
            search: true,
            pageSizeOptions: [5, 10, 20, 40],
            pageSize: parseInt(props.pageSize),
            exportButton: true,
            exportCsv: (columns, data) => {
              exportExcel(props.data);
            },
            exportPdf: (columns, data) => {
              alert('Unavailable');
            }
          }}
          title={props.title}
          icons={tableIcons}
          tableRef={tableRef}
          columns={props.columns}
          data={props.data}
        />
      </ThemeProvider>
    </div>
  )
}
export default SimpleTable;