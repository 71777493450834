import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { loginUser } from '../services/api-helper';
import {  useNavigate } from "react-router-dom";
// import { useParams, useNavigate, useLocation } from "react-router-dom";

const LoginForm = (props) => {
  let navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (username, password) => {
    try {
      const data = { email: username, password: password };
      await loginUser(data);
      // props.setUser(currentUser);
      navigate('/admin');
    }
    catch (err) {
      setErrorMessage(`Error ${err} - Invalid credentials`);
    }
  }

  return (
    <>
      <form className="form-login" onSubmit={(e) => {
        e.preventDefault();
        handleLogin(username, password);
      }} >
        <h1><span className="log-in">Log in</span></h1>
        <p className="float">
          <label for="login">Username</label>
          <input type='text' value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" />
        </p>
        <p className="float">
          <label for="password">Password</label>
          <input type='password' value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" className="showpassword" />
        </p>
          <input id="log-in" type="submit" name="submit" value="Log in" />
      </form>
      <br />
      <p>{errorMessage}</p>
    </>
  )
}

export default LoginForm;