import React, { useState, useEffect } from 'react';
import { addSaleAPI, getProductsAPI } from '../services/api-helper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaleProductsTable from './SaleProductsTable';

const AddSale = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [idCustomer, setIdCustomer] = useState(null);
  const [comments, setComments] = useState(null);
  const [dogs, setDogs] = useState(null);
  const [selectedDog, setSelectedDog] = useState(null);
  const [productList, setProductList] = useState(null);
  const [addedProducts, setAddedProducts] = useState([]);

  const getProducts = async () => {
    const prods = await getProductsAPI();
    setProductList(prods);
  }
  useEffect(() => {
    setIdCustomer(props.customer.idcustomer);
    setDogs(props.customer.dogs.map(dog => ((<option key={dog.iddog} value={dog.iddog}>{dog.name}</option>))));
    setSelectedDog(props.customer.dogs[0].iddog);
    getProducts();
  }, [props]);

  const addNewSale = async () => {
    await addSaleAPI({ comments, "startdate": startDate, "enddate": endDate, "idcustomer": idCustomer, "iddog": selectedDog, "products": addedProducts });
    props.closeModal();
  }
  const addNewProductSale = async (product) => {
    const found = productList.find(prod => prod.name === product.id);
    setAddedProducts([...addedProducts, { "idproduct": found.idproduct, "name": found.name, "adjustedprice": product['Adjusted Price'], "comments": product.Comments || null }]);
  }
  const removeNewProductSale = async (product) => {
    let remainingArr = addedProducts.filter(data => data.name !== product.id);
    setAddedProducts(remainingArr);
  }

  return (
    <div className="add-item-comp">
      <div className="mobile-header">Add Sale</div>
      <form autocomplete="off">
        <SaleProductsTable productList={productList} addNewProductSale={addNewProductSale} removeNewProductSale={removeNewProductSale} />
        <div id="sbs">
          <div>
            <label>Start Date</label>
            <DatePicker id='sale-sbs' selected={startDate} onChange={(date) => setStartDate(date)} /></div>
          <div>
            <label>End Date</label>
            <DatePicker id='sale-sbs' selected={endDate} onChange={(date) => setEndDate(date)} />
          </div>
        </div>
        <div id='sale-flex'>
          <label>Dog</label>
          <select id='sale-sbs' value={selectedDog} onChange={e => setSelectedDog(e.target.value)} name="dogs" type="customerNames" placeholder="dogs">
            {dogs}
          </select>
          <label>Comments</label>
          <textarea id="add-sales-comments" value={comments} onChange={e => setComments(e.target.value)} />
        </div>
      </form>
      <button id="add-new-item-btn" onClick={() => { addNewSale(); }} >Add</button>
    </div>
  )
}
export default AddSale;