import React from 'react';
import ecollar from '../images/ecollar.png'
import bbb from '../images/bbb.png'
import crate from '../images/Dakota-283-website-logo.webp'
import dogs from '../images/bar.jpg'

export default function Body(props) {

  return (
    <main className="main">
      <p>DOES YOUR DOG NEED BETTER MANNERS?</p>
      <p id="sl-1">DO YOU WANT TO ENJOY SUMMER WITH YOUR FURRY FRIEND?</p>
      <p>The Dog People Can Help!!</p>
      <p>Contact Us Today to Get Your Pup on the Schedule!!</p>
      {/* <p>10% Off All Board and Trains Until July 1st</p> */}
      <div id='container'>
        <img id='hero-image' src={dogs} alt=''></img>
        <div id='slogan-box'>
          <div id='slogan'>The Dog People LLC</div>
        </div>
      </div>
      <div id='magz'>
        <hr id='line'></hr>
        <img id='ecollar' src={ecollar} alt=''></img>
        {/* <img id='zinger' src={zinger}></img> */}
        <img id='bbb' src={bbb} alt=''></img>
        <img id='crate' src={crate} alt=''></img>
        <hr id='line'></hr>
      </div>
    </main >
  )
}

