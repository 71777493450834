import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import { Select, MenuItem } from "@material-ui/core";
import {
  AddBox,
  // ArrowUpward,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function SaleProductsTable(props) {

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns([
      {
        title: 'name', field: 'id',
        editComponent: ({ value, onRowDataChange, rowData }) => (
          <Select
            value={value}
            onChange={(event) => {
              onRowDataChange({
                ...rowData,
                id: (event.target.value) ?? "",
                price: props.productList.find(o => o.name === event.target.value).price,
              });
            }}
          >
            {props.productList.map((product) => (
              <MenuItem key={product.idproduct} value={product.name} >
                {product.name}
              </MenuItem>
            ))}
          </Select>
        ),  },
      { title: 'price', field: 'price', type:"currency",editable: 'never' },
      { title: 'adjustedPrice', field: 'Adjusted Price', type:"currency" },
      { title: 'comments', field: 'Comments' }
    ]);
  }, [props]);
  const [data, setData] = useState([]);

  const tableRef = useRef();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();

  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          options={{
            search: false
          }}
          title="Products"
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          data={data}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  props.addNewProductSale(newData);
                  setData([...data, newData]);
                  resolve();
                }, 1000)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  props.removeNewProductSale(oldData);
                  props.addNewProductSale(newData);
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                }, 1000)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  props.removeNewProductSale(oldData);
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),

          }
          }
        />
      </ThemeProvider>
    </div>
  )
}

export default SaleProductsTable;