import React from 'react';
// import { sendEmailAPI } from '../services/api-helper';
import ContactUsForm from './ContactUsForm';

export default function ContactUs() {

  return (
    <>
      <div className='contact-us'>
        <p id="large-title">Contact Us</p>
        <p>Email: training@thedogpeoplellc.com</p>
        <p>Phone Number: (570)-702-6685</p>
      </div>
      <ContactUsForm />
    </>
  )
}

