import React, { useState, useEffect } from 'react'
import Options from './Options';

function SearchableTextBox(props) {
  const [fieldName, setFieldName] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("Form useEffect data=", props.data)
    setData(props.data);
  }, [props]);

  const handleChange = (event) => {
    let searchName = event.target.value;
    searchName = event.target.value;
    const filtered = data.filter(
      key => key.toUpperCase().indexOf(searchName.toUpperCase()) === 0);
    setFilteredData(filtered);
    console.log("searchName=", searchName);
    setFieldName(searchName);
    props.onChange(searchName);
  }
  return (
    <>
      <input
        type="text"
        list={props.list}
        autoComplete="off"
        name="fname"
        value={fieldName}
        onChange={handleChange}
        placeholder={props.placeholder}
      />
      <Options
        filteredData={filteredData}
        list={props.list}
      />
    </>
  )
}
export default SearchableTextBox;
