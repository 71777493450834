export default function AboutUs(props) {

  return (
    <div>
      <p>The Dog People LLC is a small family owned business. We provide dog training and boarding services.  </p>
      <p>The Dog People</p>
      <p>1021 Main Street Avoca, PA 18641</p>
      <p>Contact No.: (570) 702-6685</p>
      <p>Email: training@thedogpeoplellc.com</p>
      <p>https://www.facebook.com/TheDogPeopleLLC</p>
     
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.4737231902063!2d-75.74315768430219!3d41.34205597926826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c51f8bbc7ff165%3A0x217b8bbaa6ff1887!2s1021%20Main%20St%2C%20Avoca%2C%20PA%2018641!5e0!3m2!1sen!2sus!4v1652406002757!5m2!1sen!2sus"
        width="600"
        height="450"
        title="Location"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
        </iframe>
    </div>
  )
}