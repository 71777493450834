import React from 'react';

export default function Options(props) {
  let options = '';
  if (props.filteredData) {
    let k = 0;
    options = props.filteredData.map((option) =>
      <option
        key={k++}
        value={option}>
      </option>
    );
  }
  return (
    <section id="quote">
      <datalist id={props.list}>
        {options}
      </datalist>
    </section>
  )
}

