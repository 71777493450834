import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Body from './components/Body';
// import ArtistDetails from './components/ArtistDetails';
import ContactUs from './components/ContactUs';
// import Customers from './components/Customers';
import Nav from './components/Nav';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import LoginForm from './components/LoginForm';
// import Products from './components/Products';
import Admin from './components/Admin';
import SalesChart from './components/SalesChart';

function App() {
  return (
    <div className="app" >
      <Header />
      <Nav />
      <Routes>
        <Route path="/" element={<Body />} />
        {/* <Route path="/artist" element={<ArtistDetails />} /> */}
        <Route path="/contactus" element={<ContactUs />} />
        {/* <Route path="/customers" element={<Customers />} /> */}
        <Route path="/services" element={<Services />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/login" element={<LoginForm />} />
        {/* <Route path="/products" element={<Products />} /> */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/sales" element={<SalesChart />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
