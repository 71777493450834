import React from 'react';

export default function Services(props) {

  return (
    <>
      <div className='contact-us'>
        <p id="large-title">Services</p>
      </div>
      <div id='service-box'>
        <p id="service-text"><u><b>Boarding:</b></u> Enjoy your vacation time by knowing your dog will be a part of our family and treated as
          one of our own during their time with us! Don’t worry about them being left alone because they come
          and board directly with us and we will send you updates periodically during their stay.</p>
        <ol>
          <li>$40/day for the first dog</li>
          <li>$35/day for any other dogs from the same family</li>
        </ol>
        <p><u><b>Board & Train:</b></u> Need training for your pup, but don’t necessarily have the time to dedicate to
          lessons? Want your dog to experience the world firsthand while training? The board and train is the
          right fit for you!! Your dog will come and board with a professional trainer in their home and you will
          receive daily photo and video updates. All results of a board and train are <u><b>GUARANTEED</b></u> for the life of
          the dog with <u><b>FREE</b></u> lifetime refreshers.</p>
        <ol id="arrow-list" className='service-list'>
          <li><b>1 Week Curriculum:</b>
            <ol id="dog-list">
              <li>Come</li>
              <li>Sit (Extended Sit)</li>
              <li>Place (Extended Place)</li>
              <li>Heel</li>
              <li>Break</li>
              <li>Off</li>
              <li>2 Lessons Before, 1 lesson after</li>
              <li>1.5 hour turnover to teach you
                <ol id="check-list">
                  <li>$1500-Includes boarding, training, 15ft leash, mini educator ecollar$40/day for the first dog</li>
                </ol>
              </li>
            </ol>
          </li>
          <li><b>2 Week Curriculum:</b>
            <ol id="dog-list">
              <li>Come</li>
              <li>Sit (Extended Sit)</li>
              <li>Place (Extended Place)</li>
              <li>Heel</li>
              <li>Down</li>
              <li>Distance Down</li>
              <li>Break</li>
              <li>Off</li>
              <li>Door Manners</li>
              <li>Load up into Car</li>
              <li>GUARANTEED OFF LEASH RESULTS</li>
              <li>2-3 hour turnover to teach you
                <ol id="check-list">
                  <li>$2500-Includes boarding, training, 15ft leash, mini educator ecollar</li>
                </ol>
              </li>
            </ol>
          </li>
        </ol >
        <p id="three-week"><u><b>*ASK ABOUT OUR OPTION FOR A 3 WEEK BNT AND AGGRESSION BNT*</b></u></p>
        <p id="service-text"><u><b>Lessons:</b></u>Have the time to learn the training along with your dog and want to broaden your
          knowledge to better your relationship with your dog? Lessons are the way to go for you! Come in for a
          one hour class once a week for either 4, 6, or 8 weeks. The curriculum includes come, sit, place,
          heel, down, break (release word), and off (corrective command). In the 6 and 8 week program, we
          can take you and your dog out into public to work. Lessons will allow you to be hands on with your dog
          while teaching them better manners and correcting the bad behaviors.</p>
        <ol id="arrow-list">
          <li>4 Week: $650-includes 4 one hour lessons, 15 ft leash, and mini educator ecollar</li>
          <li>6 Week: $850-includes 6 one hour lessons, 15 ft leash, and mini educator ecollar</li>
          <li>8 Week & Aggression Package: $1050-includes 8 one hour lessons, 15 ft leash, and mini educator
            ecollar
            <ol id="check-list">
              <li>Aggression package will be tailored more towards your dogs aggressive/reactive
                tendencies</li>
            </ol>
          </li>
        </ol>
      </div >
    </>
  )
}

