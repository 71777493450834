import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, AreaChart, Area, ResponsiveContainer } from 'recharts';
import { useNavigate } from "react-router-dom";

function SalesChart(props) {
  useEffect(() => {
    try {
      if (props?.currentUser) {
      } else {
        navigate('/login');
      }
    } catch (err) {
      console.log('err=', err);
      navigate('/login');
    }

  }, [props]);
  let navigate = useNavigate();
  const data = [
    {
      name: 'Page A',
      cash: 4000,
      check: 2400,
      credit:1000,
      total: 2400,
    },
    {
      name: 'Page B',
      cash: 3000,
      check: 1398,
      credit:1001,
      total: 2210,
    },
    {
      name: 'Page C',
      cash: 2000,
      check: 9800,
      credit:1002,
      total: 2290,
    },
    {
      name: 'Page D',
      cash: 2780,
      check: 3908,
      credit:10,
      total: 8698,
    },
    {
      name: 'Page E',
      cash: 1890,
      check: 4800,
      credit:100,
      total: 2181,
    },
    {
      name: 'Page F',
      cash: 2390,
      check: 3800,
      credit:500,
      total: 2500,
    },
    {
      name: 'Page G',
      cash: 3490,
      check: 4300,
      credit:600,
      total: 2100,
    },
  ];

  return (
    <>
      <h1 id="ctr">Sales By Month</h1>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="cash" stackId="1" stroke="#c47758" fill="#c47758" />
          <Area type="monotone" dataKey="check" stackId="1" stroke="#8884d8" fill="#8884d8" />
          <Area type="monotone" dataKey="credit" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
          {/* <Area type="monotone" dataKey="total" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}

export default SalesChart;