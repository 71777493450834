import axios from 'axios';
// const baseUrl = 'http://localhost:8891';
const baseUrl = 'https://api.thedogpeoplellc.com/';
const api = axios.create({
  baseURL: baseUrl
})
api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const loginUser = async (loginData) => {
  const resp = await api.post('services/auth/login', loginData,
    {
      headers: {
        "traceId": "benfromws",
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
  console.log("login resp", resp)
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}
// export const changePassword = async (loginData) => {
//   const resp = await api.post('services/auth/resetpassword', loginData)
//   localStorage.setItem('authToken', resp.data.token);
//   api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
//   return resp.data
// }
// export const resetPassword = async (resetToken, data) => {
//   const resp = await api.put(`/password_resets/${resetToken}`, data)
//   return JSON.stringify(resp.data);
// }
// export const resetPasswordInit = async (data) => {
//   const resp = await api.put(`/password_resets/new`, data)
//   return JSON.stringify(resp.data);
// }

export const registerUser = async (registerData) => {
  const resp = await api.post('/services/auth/register', registerData)
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}

export const verifyUser = async () => {
  const token = localStorage.getItem('authToken');
  // console.log("token",token)
  if (token) {
    api.defaults.headers.common.authorization = `Bearer ${token}`
    const resp = await api.get('services/auth/verify');
    // console.log("login resp",resp)
    return resp.data
  }
  return false
}

export const addCustomerAPI = async (customer) => {
  const resp = await api.post(`/customers`, customer);
  console.log(resp);
  return resp?.data?.insertId;
}
export const updateCustomerAPI = async (customer) => {
  const resp = await api.put(`/customers/${customer.idcustomer}`, customer);
  return resp.data;
}
export const deleteCustomerAPI = async (customer) => {
  console.log("deleting customer start")
  const resp = await api.delete(`/customers/${customer.idcustomer}`);
  console.log("deleting customer end")
  return resp.data;
}
export const getCustomersAPI = async () => {
  console.log("get customers start")
  const resp = await api.get(`/customers`);
  console.log("get customers end")
  return resp.data;
}
export const getCustomerAPI = async (customerId) => {
  const resp = await api.get(`/customers/${customerId}`);
  return resp.data;
}
export const addDogAPI = async (dog, images) => {
  let formData = new FormData();
  const config = {
    header: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
    }
  }
  images.forEach(async (image) =>
    formData.append(`photos`, image)
  );

  formData.append("dog", JSON.stringify(dog))
  const resp = await api.post(`/dogs`, formData, config);
  return resp.data;
}
export const addSaleAPI = async (sale) => {
  console.log("saleb=", sale);
  await api.post(`/sales`, sale);
}
export const deleteSaleAPI = async (idSale) => {
  console.log("delete idSale=", idSale);
  await api.delete(`/sales/${idSale}`);
}
export const addPaymentAPI = async (payment) => {
  console.log("payment=", payment);
  const resp = await api.post(`/payments`, payment);
  return resp.data;
}
export const deletePaymentAPI = async (idpayment) => {
  console.log("payment=", idpayment);
  const resp = await api.delete(`/payments/${idpayment}`);
  return resp.data;
}
export const deleteDogAPI = async (idDog) => {
  //insert the dog and get the iddog
  await api.delete(`/dogs/${idDog}`);
}
export const updateDogAPI = async (dog) => {
  //insert the dog and get the iddog
  // console.log("in updatedog", JSON.stringify(dog));
  await api.put(`/dogs/${dog.iddog}`, dog);
}
export const getPaymentTypesAPI = async () => {
  const resp = await api.get(`/payments/paymenttypes`);
  return resp.data;
}
export const getProductsAPI = async () => {
  const resp = await api.get(`/products`);
  return resp.data;
}
export const addProductAPI = async (data) => {
  const resp = await api.post(`/products`, data);
  console.log("resp.data=", resp.data);
  return resp?.data?.insertId;
}
export const updateProductAPI = async (id, data) => {
  const resp = await api.put(`/products/${id}`, data);
  return resp.data;
}
export const deleteProductAPI = async (id) => {
  const resp = await api.delete(`/products/${id}`);
  return resp.data;
}
export const getExpensesAPI = async () => {
  const resp = await api.get(`/expenses`);
  return resp.data;
}
export const addExpenseAPI = async (data) => {
  const resp = await api.post(`/expenses`, data);
  console.log("resp.data=", resp.data);
  return resp?.data?.insertId;
}
export const updateExpenseAPI = async (id, data) => {
  const resp = await api.put(`/expenses/${id}`, data);
  return resp.data;
}
export const deleteExpenseAPI = async (id) => {
  const resp = await api.delete(`/expenses/${id}`);
  return resp.data;
}
export const sendEmailAPI = async (name, phoneNumber, emailAddress, helpMessage) => {
  let body = { name: name, phoneNumber: phoneNumber, emailAddress: emailAddress, helpMessage: helpMessage };
  console.log(body);
  const resp = await api.post(`/email`, body);
  // return "resp";
  return resp;
}
export const getSalesReportAPI = async () => {
  const resp = await api.get(`/sales/report`);
  return resp.data;
}
export const getSalesWithPaymentsReportAPI = async () => {
  const resp = await api.get(`/sales/payments/report`);
  return resp.data;
}
export const getSalesByPaymentTypeReportAPI = async () => {
  const resp = await api.get(`/sales/payments/report/summary`);
  return resp.data;
}
export const getOutstandingReportAPI = async () => {
  const resp = await api.get(`/payments/outstanding`);
  return resp.data;
}
export const getSalesByMonthReportAPI = async () => {
  const resp = await api.get(`/sales/payments/report/bymonth`);
  return resp.data;
}